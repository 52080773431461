import React from 'react';
import PropTypes, { shape, arrayOf } from 'prop-types';
import { graphql, Link } from 'gatsby';
import cx from 'classnames';

import styles from './resource.module.sass';
import Layout from '../../components/layout';
import Banner from '../../components/blocks/banner';
import SEO from '../../components/common/seo';
import Article from '../../components/common/article';

const Resource = ({
  data: {
    markdownRemark: {
      html,
      frontmatter: { title, urlPath },
    },
    allMarkdownRemark: { nodes },
  },
}) => {
  return (
    <Layout>
      <SEO title={title} urlPath={urlPath} />
      <Banner>
        <Link className={styles.heading} to="/resources/">
          <h1 className="h2">PPP Loan Forgiveness Resource Center</h1>
        </Link>
      </Banner>
      <section className={styles.section}>
        <div className={cx('container', styles.container)}>
          <section className={styles.articleSection}>
            <Article html={html}>
              <h1>{title}</h1>
            </Article>
          </section>
          <section className={styles.related}>
            <h2 className={cx('h3', styles.relatedHeading)}>
              Related Articles
            </h2>
            <ul className={styles.linkList}>
              {nodes.map(
                ({
                  frontmatter: { urlPath: relatedUrlPath, title: relatedTitle },
                }) => {
                  return (
                    <li key={relatedUrlPath}>
                      <Link to={relatedUrlPath}>{relatedTitle}</Link>
                    </li>
                  );
                },
              )}
            </ul>
          </section>
        </div>
      </section>
    </Layout>
  );
};

Resource.propTypes = {
  data: shape({
    markdownRemark: shape({
      html: PropTypes.string,
      frontmatter: shape({ title: PropTypes.string }),
    }),
    allMarkdownRemark: shape({
      nodes: arrayOf(
        shape({
          frontmatter: shape({
            urlPath: PropTypes.string,
            title: PropTypes.string,
          }),
        }),
      ),
    }),
  }).isRequired,
};

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        urlPath
        title
      }
    }
    allMarkdownRemark(
      limit: 5
      filter: {
        id: { ne: $id }
        fileAbsolutePath: { glob: "**/markdown/resources/**" }
      }
    ) {
      nodes {
        frontmatter {
          urlPath
          title
        }
      }
    }
  }
`;

export default Resource;
